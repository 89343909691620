import React, {Component, createRef} from "react";
import {Field, getFormValues} from "redux-form";
import {Card, CardBody, CardHeader} from "reactstrap";
import PropTypes from "prop-types";
import CONFIG from "@/constants/parameters";
import {DatePickerField, FieldSelect, FieldTxt, FieldTxtIcon} from "@/components/Form/wh-field";
import {required} from "@/components/Form/wh-validator";
import PhotoPopup from "@/components/PhotoPopup";
import {connect} from "react-redux";
import {getServicesList} from "../../../Catalog/Service/redux";
import * as memberService from "../../../../services/member";
import {getUserRoles} from "../../../Authentication/redux";
import {formName} from "../../constants";

const popupPictureTitle = "Photo pièce d’identité";
const popupPictureFieldName = "identityCapture";
const popupRibTitle = "Photo du rib";
const popupRibFieldName = "ribCapture";

class ContractForm extends Component {
	popupPicture = createRef();
	popupRib = createRef();
	state = {
		users: [],
		loaded: false,
		open: false,
	};

	load = state => {
		this.setState({
			loaded: false
		});

		return memberService
			.search({
				filtered: [
					{id: "pagination", value: false},
					{id: "enabled", value: true}
				]
			})
			.then(data => {
				this.setState(
					{
						data: []
					},
					() => {
						this.setState({
							users: data["hydra:member"]
								.filter(u => u.roles.includes("ROLE_SELLER") || u.roles.includes("ROLE_TECHNICO_COMMERCIAL"))
								.map(u => ({
									value: u["@id"],
									name: u.firstname + " " + u.lastname
								})),
							pages: Math.ceil(
								data["hydra:totalItems"] / state.pageSize
							),
							loaded: true
						});
					}
				);
			});
	};

	componentDidMount = () => {
		const initialValues = this.props.initialValues;

		this.load(this.state);

		if (initialValues && initialValues.levelService) {
			this.props.change("levelService", initialValues.levelService);
		}
	};

	readerOnLoad = (file, type) => (e) => {
		this.props.change(type, e.target.result);
	}

	identityInsert = (img, label) => {
		document.getElementById(label).innerHTML = img.target.files[0].name;
		const reader = new FileReader();
		reader.readAsDataURL(img.target.files[0]);
		const file = img.target.files[0]
		if (label === 'labelIdentity') {
			reader.onload = this.readerOnLoad(file,"identityCapture");
		} else if (label === 'LabelOldContract') {
			reader.onload = this.readerOnLoad(file,"oldContract");
		} else {
			reader.onload = this.readerOnLoad(file,"ribCapture");
		}
	}



	download = (value) => {
		const base64WithoutPrefix = value.split(',');
		const type = base64WithoutPrefix[0].split(':')[1].split(';')[0]
		const bytes = atob(base64WithoutPrefix[1]);
		let length = bytes.length;
		let out = new Uint8Array(length);

		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}

		const blob = new Blob([out], {type: type});
		const blobURL = URL.createObjectURL(blob);
		window.open(blobURL);
	}

	render() {
		const {
			change,
			identityCapture,
			ribCapture,
			paymentType,
			amountCalculator,
			levelServices,
			isArchived,
			roles,
			oldContract,
			currentValues,
			initialValues,
			isArchivedContract,
			userIsAdmin,
			require
		} = this.props;

		const checkType = (img) =>{
			const type = img.split(":")[1].split("/")[0]
			return type === 'image';
		}
		const req = require ? [required] : []
		let imgRib = true
		if (ribCapture){
			imgRib = checkType(ribCapture)
		}
		let imgIdentity = true
		if (identityCapture){
			imgIdentity = checkType(identityCapture)
		}

		let imgOldContract = true
		if (oldContract){
			imgOldContract = checkType(oldContract)
		}

		const isAdmin = roles.includes("ROLE_ADMIN")
		const enableFirstReglement = currentValues.contractInformation.find(a => a.type.id === 1 || a.type.id === 2);
		const levelOptions = levelServices.map(item => ({
			name: item.name,
			value: item["@id"]
		}));
		const isSynchronised = !!initialValues.synchronised

		const fieldDisabled = (isArchivedContract && !userIsAdmin) || !!this.props.initialValues.contractSignature

		return (
			<div>
				<Card>
					<PhotoPopup
						ref={this.popupPicture}
						change={change}
						title={popupPictureTitle}
						fieldName={popupPictureFieldName}
					/>

					<CardBody>
						{isAdmin &&
							<Field
								component={DatePickerField}
								label="* Date de création :"
								name="created"
								validate={req}
								isClearable={false}
								disabled={fieldDisabled}
							/>}
						<Field
							label="Choix du contrat :"
							name="levelService"
							component={FieldSelect}
							validate={req}
							onChange={e => {
								amountCalculator(e.target.value);
							}}
							options={levelOptions}
							disabled={fieldDisabled}
						/>

						<Field
							label="Montant du mensuel :"
							name="monthly"
							component={FieldTxtIcon}
							disabled={true}
							append="€ / mois"
							type="text"
						/>

						<div>
							<Field
								label="Promotion nouveau client :"
								name="promotion"
								component={FieldSelect}
								options={CONFIG.PROMOTION}
								disabled={fieldDisabled}
							/>

							{enableFirstReglement !== undefined &&
								<Field
									label="1er règlement à 60€ :"
									name="firstReglement"
									component={FieldSelect}
									options={CONFIG.REGLEMENT}
									disabled={fieldDisabled}
								/>
							}

							{enableFirstReglement !== undefined &&
								<Field
									label="Thermostat offert :"
									name="thermostatOffer"
									component={FieldSelect}
									options={CONFIG.THERMOSTAT}
									disabled={fieldDisabled}
								/>
							}

							<Field
								label="Condition de règlement :"
								name="paymentType"
								component={FieldSelect}
								validate={req}
								options={CONFIG.PAYMENTS_TYPES}
								disabled={fieldDisabled}
							/>

							{/*<Field*/}
							{/*	label="Date d'installation :"*/}
							{/*	name="installationDate"*/}
							{/*	component={FieldTxt}*/}
							{/*	type="date"*/}
							{/*	disabled={isArchived}*/}
							{/*/>*/}

							<Field
								label="Installateur :"
								id="field-form"
								name="fitter"
								component={FieldTxt}
								type="text"
								disabled={fieldDisabled}
								placeholder="Nom Prénom"
							/>

							<Field
								label=" * N° pièce d'identité :"
								id="field-form"
								name="identity"
								component={FieldTxt}
								validate={req}
								type="text"
								disabled={fieldDisabled}
								placeholder="Numéro"
							/>

							<Field
								label="Commentaire :"
								name="note"
								component={FieldTxt}
								type="textarea"
								disabled={false}
							/>

							{identityCapture && imgIdentity && (
								<img src={identityCapture} width="100%"/>
							)}
							{identityCapture && !imgIdentity &&(
								<div onClick={() => this.download(this.props.identityCapture)} disabled={fieldDisabled}
									 className="btn btn-block btn-success btn-lg">
									Voir l'identité
								</div>
							)}
							{!isArchived && (
								<a
									href="javascript:void(0)"
									className="btn btn-primary btn-block"
									onClick={() =>
										this.popupPicture.current.handleOpen()
									}
								>
									<i className="fa fa-picture"/> Prendre en
									photo la pièce d{"'"}
									identité
								</a>
							)}
							<label className={"form-control-label"}>Ou</label>
							<div className="custom-file">
								<input type="file" className="custom-file-input clicker"
									   accept="image/*, application/pdf"
									   onChange={(e) => this.identityInsert(e, 'labelIdentity')} id="identityCapture"/>
								<label className="custom-file-label clicker" id="labelIdentity">Ajoutez
									un fichier</label>
							</div>
						</div>
					</CardBody>
				</Card>

				{paymentType && paymentType === "sepa" && (
					<Card>
						<PhotoPopup
							ref={this.popupRib}
							change={change}
							title={popupRibTitle}
							fieldName={popupRibFieldName}
						/>

						<CardHeader>
							<i className="fa fa-euro"/> Rib
						</CardHeader>

						<CardBody>
							<Field
								label=" * Iban :"
								id="field-form"
								name="iban"
								validate={req}
								component={FieldTxt}
								format={(value, name) => {
									if (value){
										return value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
									}

								}}
								type="text"
								placeholder="FR76 0000 0000 0000 0000 0000 123"
							/>

							<Field
								label=" * Bic :"
								id="field-form"
								name="bic"
								validate={req}
								component={FieldTxt}
								type="text"
								placeholder="BANK FRXX XXX"
							/>

							{ribCapture && imgRib && (
								<img src={ribCapture} width="100%"/>
							)}
							{ribCapture && !imgRib &&(
								<div onClick={() => this.download(this.props.ribCapture)} disabled={fieldDisabled}
									 className="btn btn-block btn-success btn-lg">
									Voir le RIB
								</div>
							)}
							{!isArchived && (
								<a
									href="javascript:void(0)"
									className="btn btn-primary btn-block"
									onClick={() =>
										this.popupRib.current.handleOpen()
									}
								>
									<i className="fa fa-picture"/> Prendre en
									photo le rib
								</a>
							)}
							<label className={"form-control-label"}>Ou</label>
							<div className="custom-file">
								<input type="file" className="custom-file-input clicker"
									   accept="image/*, application/pdf"
									   onChange={(e) => this.identityInsert(e, "labelRib")} id="ribCapture"/>
								<label className="custom-file-label clicker" id="labelRib">Ajoutez
									un RIB</label>
							</div>
						</CardBody>
					</Card>

				)}
				{roles.includes("ROLE_SELLER_SENIOR") && (
					<Card>
						<CardHeader>
							<i className="fa fa-user"/> Affectation du contrat
						</CardHeader>
						<CardBody>
							<Field
								label="Affecter ce contrat au vendeur :"
								name="seller"
								component={FieldSelect}
								options={this.state.users}
								required={false}
							/>
						</CardBody>
					</Card>
				)}
				{roles.includes("ROLE_ADMIN") && isSynchronised && (
					<Card>
						<CardHeader>
							<i className="fa fa-user"/> Ancien contrat
						</CardHeader>
						<CardBody>
							{oldContract && imgOldContract && (
								<img src={oldContract} width="100%" className={"mb-2"}/>
							)}
							{oldContract && !imgOldContract && (
								<div>
									<div onClick={() => this.download(this.props.oldContract)} disabled={fieldDisabled}
										 className="btn btn-block btn-success btn-lg mb-2">
										Voir l'ancien contrat
									</div>
								</div>
							)}
							<div className="custom-file">
								<input type="file" className="custom-file-input clicker" accept="application/pdf,image/*"
									   onChange={(e) => this.identityInsert(e, "LabelOldContract")}
									   id="oldContract"/>
								<label className="custom-file-label clicker"  id="LabelOldContract">Importer un ancien
									contrat</label>
							</div>
						</CardBody>
					</Card>
				)}
			</div>
		);
	}
}

ContractForm.defaultProps = {
	levelService: undefined,
	oldContract: undefined,
	identityCapture: undefined,
	ribCapture: undefined,
	paymentType: undefined,
	created: undefined
};

ContractForm.propTypes = {
	change: PropTypes.func.isRequired,
	oldContract: PropTypes.string,
	identityCapture: PropTypes.string,
	ribCapture: PropTypes.string,
	paymentType: PropTypes.string,
	amountCalculator: PropTypes.func.isRequired,
	levelServices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	isArchived: PropTypes.bool.isRequired,
	initialValues: PropTypes.shape({}).isRequired,
	currentValues: PropTypes.shape({}),
};
export const mapStateToProps = state => ({
	levelServices: getServicesList(state),
	roles: getUserRoles(state),
	currentValues: getFormValues(formName)(state),
});

export default connect(mapStateToProps)(ContractForm);
