import React, {Component} from 'react';
import {Button, Modal, ModalHeader} from 'reactstrap';
import Loading from '../../../../../components/Loading';
import PopupContractForm from "./Form";
import {getDefaultData} from "../../../utils";

class PopupContract extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: {},
			loaded: true,
		};

	}

	handleOpen = () => {
		this.setState({
			open: true
		});

		this.setState({
			loaded: false
		});


	}

	handleClose = () => {
		this.setState({
			open: false,
		});
	}

	onSubmit = (data) => {
		console.log(data)
		const dataToSend = getDefaultData();
		if (data.firstName){
			dataToSend.customer.firstName = data.firstName
		}
		if (data.lastName){
			dataToSend.customer.lastName = data.lastName
		}
		this.props.create(dataToSend)
	}

	render() {
		const {className} = this.props;
		const {loaded, loading} = this.state;
		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				{loading && <Loading/>}
				<ModalHeader toggle={() => this.handleClose()}>Créer un nouveau contrat</ModalHeader>
				<PopupContractForm onSubmit={this.onSubmit} create={this.props.create} initialValues={this.state.data}/>

			</Modal>
		)
	}
}

export default PopupContract;
