import React, {Component, createRef} from "react";
import {Badge, Button, Card, CardHeader, Input} from "reactstrap";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";
import {bindActionCreators} from "redux";
import {toast} from "react-toastify";
import WHTable from "../../../components/WHTable/index";
import PopupSearchCostumer from "@/modules/Customer/SearchPopup/";
import PopupExportArchived from "./Archived/export/ExportPopup";
import {ROLE_ADMIN, ROLE_SELLER, ROLE_SELLER_SENIOR, ROLE_TECHNICO_COMMERCIAL} from "@/constants/roles";
import EuroFormat from "@/components/EuroFormat/EuroFormat";
import {addContractToSync, getContractsStatus, syncAllContracts, syncOneContract} from "../redux";
import {getIsSeller, getUserRoles} from "../../Authentication/redux";
import {redirectTo} from "@/utils/redirectTo";
import {CONTRACTS, CONTRACTS_TO_SYNC} from "../../../constants/paths";
import {getIsOnline} from "../../../components/OnlineStatus/redux";
import {checkIfContractValid, getDefaultData} from "../utils";
import {fetchContract, setStatusFilter} from "../redux/actions";
import {getHref, getStatusFilterValue, saveStatusFilterValue} from "./utils";
import "./styles.scss";
import Swal from 'sweetalert2';
import Select from "react-select";
import {DateField} from "../../../components/Form/wh-field";
import * as sectorsService from "../../../services/sectors";
import * as teamService from "../../../services/team";
import * as memberService from "../../../services/member";
import * as contractService from "../../../services/contract";
import ExportPopup from "../Export/ExportPopup";
import moment from "moment";
import {remove} from "../../../services/contract";
import PopupContract from "./ToSync/popup";


class ContractsList extends Component {

	popup = createRef();
	createPopup = createRef();
	constructor(props) {
		super(props);
		this.defaultStatusFilter = getStatusFilterValue();
		this.customFilters = []

		this.state = {
			initialCall: true,
			storageFilters: [],
			customFiltered: [],
			"seller.team.sector": null,
			"seller.team": null,
			"seller": null,
			deletePending: false,
			archivePending: false,
			checkedContract: [],
			countTest: 0,
			absoluteFilters: [],
			loaded: true,
			exchangePending: false,
		};
	}

	btnSync = (data) => {
		if (data.paymentType === "sepa") {
			return true
		}
		return data.paymentType === "payment_entretien";
	}

	async componentDidMount() {
		this.props.setParentState({loaded: true});
		this.loadTeams()
		this.loadSector()
		this.loadMembers()
		this.getFiltersStorage()
	}

	async componentDidUpdate() {
		this.putFiltersStorage()
		this.useFiltersStorage()
	}

	columns = () => {
		const columns = [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				width:
					(this.state.deletePending || this.state.archivePending)
						? 50
						:!this.props.isArchivedContracts &&
						!this.props.isSyncedContracts
							? 170
							: this.props.roles.includes("ROLE_ADMIN")
								? 130
								: 50,
				Cell: row => {



					return (
					<div>
						{!this.props.isArchivedContracts &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<Button
								color="info"
								onClick={() => this.onEdit(row.original)}
							>
								<i className="fa fa-edit"/>
							</Button>
						)}

						{this.props.isArchivedContracts &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<Button
								color="info"
								onClick={() => this.onEdit(row.original)}
							>
								<i className="fa fa-eye"/>
							</Button>
						)}

						{((this.props.roles.includes('ROLE_ADMIN')) ||
						(!this.props.isSyncedContracts))  &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<Button
								color="danger"
								onClick={() => {
									this.onRemove(row.original);
								}}
							>
								<i
									className="fa fa-trash"
									aria-hidden="true"
								/>
							</Button>
						)}

						{this.props.roles.includes('ROLE_ADMIN') &&
						this.props.isSyncedContracts &&
						!this.props.isArchivedContracts &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<Button
								color="warning"
								onClick={() => {
									this.onArchive(row.original, true);
								}}
							>
								<i
									className="fa fa-archive"
									aria-hidden="true"
								/>
							</Button>
						)}

						{this.props.roles.includes('ROLE_ADMIN') &&
						this.props.isSyncedContracts &&
						this.props.isArchivedContracts &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<Button

								color="warning"
								onClick={() => {
									this.onArchive(row.original, false);
								}}
							>
								<i
									className="fa fa-archive"
									aria-hidden="true"
								/>
							</Button>
						)}

						{this.props.isOnline &&
						!row.value &&
						this.btnSync(row.original) &&
						checkIfContractValid(row.original) &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<Button
								color="success"
								onClick={() => {
									this.props.onSyncContract(
										row.original.tmpID
									);
								}}
							>
								<i
									className="fa fa-refresh"
									aria-hidden="true"
								/>
							</Button>
						)}

						{this.props.isOnline && !row.value &&
						(!this.state.deletePending && !this.state.archivePending) && (
							<a href={getHref(row.original)}>
								<Button color="warning">
									<i
										className="fa fa-exclamation-triangle"
										aria-hidden="true"
										title="envoyer un mail d'erreur"
									/>
								</Button>
							</a>
						)}
						{(this.state.deletePending || this.state.archivePending) && (
							<input
								id={`checkedContract${row.index}`}
								type="checkbox"
								className="inputCheckBox"
								onClick={(elem) => {this.getElemCheck(row.value, row.original, elem.target.checked)} }
							/>
						)}
					</div>
					)}
			},
			{
				Header: "Statut",
				accessor: "status",
				Cell: row => (
					<Badge color={row.original.statusColor}>
						{row.original.statusName}
					</Badge>
				),
			},
			{
				Header: "Date",
				accessor: "created",
				Cell: row =>(
					<>
					<Moment format="DD/MM/YYYY HH:mm">{row.value}</Moment>
					</>
				),
				show: this.props.contractType !== CONTRACTS_TO_SYNC,
			},
			{
				Header: "Date du dernier entretien",
				accessor: "entretienDate",
				show: this.props.contractType !== CONTRACTS_TO_SYNC,
				Cell: row =>(
						<>
							{row.value &&
								<Moment format="DD/MM/YYYY HH:mm">{row.value}</Moment>
							}
							{!row.value &&
								<p>Aucun entretien</p>
							}
						</>
					)
			},
			{
				Header: "Client",
				accessor: "customer.lastName",
				Cell: row => (
					<div>{
						`${row.original.customer.firstName || ""}
						${row.original.customer.lastName || ""}`
					}</div>
				),
			},
			{
				Header: "Ville",
				accessor: "customer.city"
			},
			{
				Header: "Mobile",
				accessor: "customer.mobile",
			},
			// {
			// 	Header: "Téléphone",
			// 	accessor: "customer.phone",
			// },
			// {
			// 	Header: "Montant",
			// 	accessor: "monthly",
			// 	Cell: row => <div className="cell-montant-contrat">
			// 		<EuroFormat value={row.value * 12}/>
			// 		<div className="cell-contrat-bold">
			// 			{row.original.firstReglement === true ? "60 €" : ""}
			// 		</div>
			// 	</div>,
			// },
			{
				Header: "Vendeur",
				accessor: "seller.name",
				show: this.props.contractType !== CONTRACTS_TO_SYNC,
			}
		];

		return this.props.isArchivedContracts || this.props.isSyncedContracts
			? columns
			: columns.filter(x => x.accessor !== "status");
	};

	onRemove = ({id, tmpID}) => {
		const toDelete = tmpID || id;
		Swal.fire({
			title: "Supprimer",
			text: 'Êtes-vous sûr de vouloir supprimer ce contrat ?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then(async (result) => {
			if (result.isConfirmed) {
				this.setState({
					loaded: false
				});
				try {
					(this.props.contractType === CONTRACTS_TO_SYNC)
						? await this.props.onRemove(toDelete)
						: await remove(toDelete)
					await this.filterReload();
					Swal.fire({
						title: 'Le contrat a bien été supprimé',
						icon: 'success',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}catch (e) {
					Swal.fire({
						title: "Le contrat n'a pas été supprimé",
						icon: 'error',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}
				this.setState({
					loaded: true
				});
			} else if (result.isDenied) {
				Swal.fire({
					title: "Le contrat n'a pas été supprimé",
					icon: 'error',
					timer: 1500,
					timerProgressBar: true,
					showConfirmButton: false,
				})
			}
		})
	};

	onArchive = async (contract, archived) => {
		let txt = "archiver"
		if (!archived){
			txt = "désarchiver"
		}
		Swal.fire({
			title: `${txt}`,
			text: `Êtes-vous sûr de vouloir ${txt} ce contrat ?`,
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: `${txt}`,
			denyButtonText: 'Annuler',
		}).then( async (result) => {
			if (result.isConfirmed) {
				this.setState({
					loaded: false
				});
				try {
					contract.archived = archived
					await contractService.update(contract)
					await this.filterReload();
					Swal.fire({
							title: `Le contrat a bien été ${txt}`,
							icon: 'success',
							timer: 1500,
							timerProgressBar: true,
							showConfirmButton: false,
						}
					)
				}catch (e) {
					Swal.fire({
						title: `Le contrat n'a pas été ${txt}`,
						icon: 'error',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}
				this.setState({
					loaded: true
				});
			} else if (result.isDenied) {
				Swal.fire({
					title: `Le contrat n'a pas été ${txt}`,
					icon: 'error',
					timer: 1500,
					timerProgressBar: true,
					showConfirmButton: false,
				})
			}
		})
	}


	onCheckedMultiDeleteContrat = async () => {
		if (this.state.checkedContract.length > 0) {
			const numberContract = this.state.checkedContract.length
			Swal.fire({
				title: "Supprimer",
				text: `Êtes-vous sûr de vouloir supprimer ${numberContract} contrats ?`,
				icon: 'question',
				showDenyButton: true,
				confirmButtonColor: '#3085d6',
				denyButtonColor: '#d33',
				confirmButtonText: 'Supprimer',
				denyButtonText: 'Annuler',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.setState({
						loaded: false
					});
					try {
						if (this.props.isSyncedContracts) {
							await contractService.multiDelete(this.state.checkedContract)
						} else {
							this.state.checkedContract.forEach(element => this.props.onRemove(element))
						}
						await this.props.onLoad()
						Swal.fire({
							title: `${numberContract} contrats ont bien été supprimé`,
							icon: 'success',
							timer: 1500,
							timerProgressBar: true,
							showConfirmButton: false,
						})
						this.setState({deletePending: false, archivePending: false})
						this.setState({checkedContract: []})
					}catch (e) {
						Swal.fire({
							title: `${numberContract} contrat n'ont pas été supprimé`,
							icon: 'error',
							timer: 1500,
							timerProgressBar: true,
							showConfirmButton: false,
						})
					}
					this.setState({
						loaded: true
					});
				} else if (result.isDenied) {
					Swal.fire({
						title: `${numberContract} contrat n'ont pas été supprimé`,
						icon: 'error',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Erreur',
				text: `Veuillez sélectionner au moins 1 contrat, vous avez sélectionner ${this.state.checkedContract.length} contrat`,

			})
		}
	}

	onCheckedMultiContrat = async (archived) => {
		let txt = "archiver"
		if (!archived){
			txt = "désarchiver"
		}
		if (this.state.checkedContract.length > 0) {
			const numberContract = this.state.checkedContract.length
			Swal.fire({
				title: `${txt}`,
				text: `Êtes-vous sûr de vouloir ${txt} ${numberContract} contrats ?`,
				icon: 'question',
				showDenyButton: true,
				confirmButtonColor: '#3085d6',
				denyButtonColor: '#d33',
				confirmButtonText: `${txt}`,
				denyButtonText: 'Annuler',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.setState({
						loaded: false
					});
					try {
						if (archived){
							await contractService.multiArchived(this.state.checkedContract)
						}else{
							await contractService.multiUnarchived(this.state.checkedContract)
						}

						await this.props.onLoad()
						this.setState({deletePending: false, archivePending: false})
						this.setState({checkedContract: []})
						Swal.fire({
							title: `${numberContract} contrats ont bien été ${txt}`,
							icon: 'success',
							timer: 1500,
							timerProgressBar: true,
							showConfirmButton: false,
						})
					} catch (e) {
						Swal.fire({
							title: `${numberContract} contrat n'a pas été ${txt}`,
							icon: 'error',
							timer: 1500,
							timerProgressBar: true,
							showConfirmButton: false,
						})
					}
					this.setState({
						loaded: true
					});
				} else if (result.isDenied) {
					Swal.fire({
						title: `${numberContract} contrat n'a pas été ${txt}`,
						icon: 'error',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Erreur',
				text: `Veuillez sélectionner au moins 1 contrat, vous avez sélectionner ${this.state.checkedContract.length} contrat`,

			})
		}
	}


	totalParc = (filter, parc) => {
		let filtre = []
		if (this.props.isSyncedContracts && !this.props.isArchivedContracts) {
			filtre = [{id: "synchronised", value: true}, {id: "archived", value: false}, ...filter]
		}
		if (this.props.isArchivedContracts) {
			filtre = [{id: "archived", value: true}, ...filter]
		}
		contractService.search({
			filtered: filtre
		}).then(async data => {
				if (parc) {
					await this.setState({
						totalParc: data["hydra:totalItems"],
					});
				} else {
					await this.setState({
						totalPasParc: data["hydra:totalItems"],
					});
				}
			}
		);
	}

	onLoad = async state => {
		this.setState({
			loaded: false
		});
		saveStatusFilterValue(state);
		state.filtered = this.state.customFilters ? this.state.customFilters : []
		if (this.state.initialCall) {
			let initialPage =  localStorage.getItem(`${this.props.isArchivedContracts ? "archived" : "synced"}PageNumber`)
			if (initialPage) {
				initialPage = parseInt(initialPage)

				if (initialPage >= 1) {
					this.setState({initialPage})
				}
			}
			this.state.absoluteFilters.push({id: "page", value: initialPage ? initialPage + 1 : 1})
			this.setState({ initialCall: false })
			this.setState({
				loaded: true
			});
		} else {
			this.setState({ initialPage: undefined })
			localStorage.setItem(`${this.props.isArchivedContracts ? "archived" : "synced"}PageNumber`, state.page)
			const page = [{id:"page", value: state.page + 1},{id:"pageSize", value: state.pageSize}]
			const filters =  localStorage.getItem("contract_filters")
			const allFilters = JSON.parse(filters).concat(...page)
			await this.props.onLoad({filtered: allFilters});

			this.setState({
				loaded: true
			});
		}
	};

	onFilterReload = async (customFilters) => {
		this.setState({
			loaded: false
		});
		this.setState({customFilters: customFilters})
		if (customFilters) {
			this.totalParc([...customFilters, {id: "parc", value: true}], true)
			this.totalParc([...customFilters, {id: "parc", value: false}], false)
			const allFilters = this.state.absoluteFilters.concat(...customFilters)
			await this.props.onLoad({filtered: allFilters});
		} else {
			this.totalParc([{id: "parc", value: true}], true)
			this.totalParc([{id: "parc", value: false}], false)
			await this.props.onLoad({filtered: this.state.absoluteFilters});
		}
		this.setState({
			loaded: true
		});
		if (this.props.onCustomFiltersChange) {
			this.props.onCustomFiltersChange(customFilters)
		}
	};

	onSyncAll = async () => {
		const toBeSynchronised = await this.props.onSyncAll({
			filtered: getStatusFilterValue()
		})
		toBeSynchronised ? toast.success("contrats synchronisés") : toast.warn("aucun contrat ne peut être synchronisé")
	};

	onEdit = async data => {
		if (this.props.isSyncedContracts) {
			await this.props.onFetchCurrentContract(data.id);
		}
		redirectTo(CONTRACTS, data.tmpID || data.id);
	};

	Gocreate = () => {
		this.createPopup.current.handleOpen()
	};
	create = (dataToSend) => {
		this.props.onSaveContract(dataToSend);
		this.onEdit(dataToSend);
	};


	loadSector = () => {
		return sectorsService.search().then(data => {
			this.setState(
				{
					dataSector: []
				},
				() => {
					this.setState({
						dataSector: data["hydra:member"],
					});
				}
			);
		});
	}
	loadTeams = () => {
		return teamService.search().then(data => {
			this.setState(
				{
					dataTeams: []
				},
				() => {
					this.setState({
						dataTeams: data["hydra:member"],
					});
				}
			);
		});
	}

	alreadyInstall = () => {
		return [{
			value: 0,
			label: "non"
		}, {
			value: 1,
			label: "oui"
		}]
	}

	thermostatOffer = () => {
		return [{
			value: 0,
			label: "non"
		}, {
			value: 1,
			label: "oui"
		}]
	}

	loadMembers = () => {
		return memberService
			.search({
				filtered: [
					{id: "pagination", value: false},
					{id: "enabled", value: true}
				]
			}).then(data => {
				this.setState(
					{
						dataMembers: []
					},
					() => {
						this.setState({
							dataMembers: data["hydra:member"],
						});
					}
				);
			});
	}

	addFilter = async (option, id, testPending) => {

		const newFiltered = [...this.state.customFiltered]
		const filters = this.state.storageFilters
		if (this.props.contractType !== CONTRACTS_TO_SYNC) {
			localStorage.setItem("contract_filters", JSON.stringify(filters));
		}

		const filterIndex = newFiltered.findIndex((filter) => {
			return filter.id === id
		})
		this.setState({ initialPage: 0 })
		if (option === null) { // quand on supp un filtre
			newFiltered.splice(filterIndex, 1)
			const newState = {customFiltered: newFiltered, [id]: null}
			const finalFiltered = newFiltered.concat({id: 'page' , value: 1})
			await this.setState(newState)
			await this.onFilterReload(finalFiltered)
		} else { // quand on rajoute un filtre
			const value = option.value

			const newFilter = {id: id, value}
			if (filterIndex === -1) {
				newFiltered.push(newFilter)
			} else {
				newFiltered[filterIndex] = newFilter
			}
			const newState = {customFiltered: newFiltered, [id]: value}
			await this.setState(newState);

			const finalFiltered = newFiltered.concat({id: 'page' , value: 1})

			if (testPending !== true) {
				await this.onFilterReload(finalFiltered)
			}
		}

		this.setState({ initialPage: undefined })

	}

	useFiltersStorage = async () => {


		if (this.props.contractType !== CONTRACTS_TO_SYNC) {
			const storageFilters = this.state.storageFilters
			if (storageFilters && this.state.countTest < 1) {
				await this.setState({countTest: this.state.countTest + 1})
				const objStorageFiltersState = storageFilters.reduce((previousValue, currentValue) => {
					return ({
						...previousValue,
						[currentValue.id]: currentValue.value
					})
				}, {})
				const newState = {customFiltered: storageFilters}
				this.setState(objStorageFiltersState);
				this.setState(newState)
				await this.onFilterReload(storageFilters)
			}
		}
	}


	filterReload = async () => {
		if ((this.state['created[after]']) || (this.state['created[before]'])) {
			await this.addFilter({value: this.state['created[after]'] ? moment(this.state['created[after]']).format('YYYY-MM-DD') : undefined}, "created[after]", true)
			await this.addFilter({value: this.state['created[before]'] ? moment(this.state['created[before]']).format('YYYY-MM-DD') : undefined}, "created[before]", false)
		} else {
			this.props.onLoad()
		}
	}

	allSelect = () => {
		for(let i = 0 ;i < this.props.data.length ; i++ ) {
			document.getElementById(`checkedContract${i}`).checked = "checked";
		}

		this.state.checkedContract = this.props.data.map(elem => {
			let allID = []
			allID = elem.id
			return allID
		})
	}

	cancelCheckButton = () => {
		this.setState({deletePending: false, archivePending: false})
		this.setState({checkedContract: []})
	}

	getElemCheck = (id, contract, elem) => {
			if (elem) {
				const data = [...this.state.checkedContract, this.props.isSyncedContracts ?  id : contract.tmpID]
				this.setState({checkedContract: data})
			}

			if (!elem) {
				const data = (element) =>  this.props.isSyncedContracts ? element === id : element === contract.tmpID;
				const indexContract = this.state.checkedContract.findIndex(data)
				this.state.checkedContract.splice(indexContract, 1)
			}
	}

	putFiltersStorage = () => {
		if (this.props.contractType !== CONTRACTS_TO_SYNC) {
			const filters = this.state.customFiltered
			localStorage.setItem("contract_filters", JSON.stringify(filters));
		}
	}

	getFiltersStorage = () => {
		if (this.props.contractType !== CONTRACTS_TO_SYNC) {
			const filters = localStorage.getItem("contract_filters")
			this.setState({storageFilters: JSON.parse(filters)})
		}
	}

	exchangeName = async () => {
		this.setState({exchangePending: true})
		const firstname = this.state["customer.firstName"]
		const lastName = this.state["customer.lastName"]
		await this.addFilter({value: firstname}, "customer.lastName", true)
		await this.addFilter({value: lastName}, "customer.firstName", false)
		this.setState({exchangePending: false})
	}

	reloadFilters = async () => {
		const filters = {
			status: null,
			thermostatOffer: null,
			alreadyInstalled: null,
			'created[after]': null,
			'created[before]': null,
			'seller.team.sector': null,
			'seller.team': null,
			'seller': null,
			'customer.firstName': "",
			'customer.lastName': "",
			'customer.city': "",
		}
		this.setState(filters)
		this.setState({storageFilters: []})
		this.setState({customFiltered: []})

		localStorage.setItem("contract_filters", null)
		await this.props.onLoad()
		await this.onFilterReload()
	}


	render() {
		const {
			data,
			loaded,
			roles,
			manual,
			isArchivedContracts,
			isSyncedContracts,
			pages
		} = this.props;

		const {columns, onLoad, onEdit, create, onSyncAll, addFilter, Gocreate} = this;
		const isSeller =
			roles &&
			(roles.indexOf(ROLE_SELLER) > -1 ||
				roles.indexOf(ROLE_SELLER_SENIOR) > -1 ||
				roles.indexOf(ROLE_TECHNICO_COMMERCIAL) > -1);

		const isAdmin =
			roles &&
			(roles.indexOf(ROLE_ADMIN) > -1);

		const isToSyncedContrats = this.props.contractType === CONTRACTS_TO_SYNC

		let title = "Liste des contrats";

		if (isArchivedContracts) {
			title = "Liste des contrats archivés";
		} else if (isSyncedContracts) {
			title = "Liste des contrats synchronisés";
		}

		if (this.state['created[after]']) {
			this.state['created[after]'] = new Date(this.state['created[after]'])
		}
		if (this.state['created[before]']) {
			this.state['created[before]'] = new Date(this.state['created[before]'])
		}

		const showTeams = () => {
			return this.state.dataTeams ? this.state.dataTeams.map(team => ({
				value: team.id,
				label: team.name,
			})) : []
		}

		const showTeamsSectors = (value) => {
			if (value && this.state.dataSector) {
				const sectorFind = this.state.dataSector.find(sector => sector.id === value)
				return sectorFind ? sectorFind.teams.map(team => ({
					value: team.id,
					label: team.name,
				})) : []
			}
		}

		const showSellers = () => {
			return this.state.dataMembers ? this.state.dataMembers.map(seller => ({
				value: seller.id,
				label: seller.name,
			})) : []
		}

		const showSellersTeams = (value) => {
			if (value && this.state.dataTeams) {
				const teamFind = this.state.dataTeams.find(team => team.id === value)
				return teamFind ? teamFind.users.map(team => ({
						value: team.id,
						label: team.name,
					}))
					: []
			}

		}

		return (
			<div className="animated fadeIn">

				<PopupSearchCostumer ref={this.popup} edit={onEdit}/>
				<PopupExportArchived ref={"archivedPopup"}/>
				<PopupContract create={create} ref={this.createPopup}/>
				<ExportPopup ref="exportPopup"/>

				<Card className="border-card">
					<CardHeader className="card-header-contract">
						<div className="header-button-contract">
							<div>
								<h2 className="title-contract">{title}</h2>
							</div>
							<div className=" group-button-contract ">
								{(this.state.deletePending || this.state.archivePending) &&
								isAdmin &&
									<>
										<Button
											className="button-cancel button-text"
											onClick={() => this.cancelCheckButton()}
										> Annuler
										</Button>
										<Button
											className="button-cancel button-text"
											onClick={() => this.allSelect()}
										> Tout Selectionner
										</Button>
									</>
								}
								{ this.state.deletePending && isAdmin &&
									<Button
										className="button-remove button-text button-shift"
										onClick={() => this.onCheckedMultiDeleteContrat()}
										type="submit"
									>
										<i className="fa fa-trash fa-lg icone-button-text"> </i> Supprimer
									</Button>
								}
								{ this.state.archivePending && isAdmin &&
								!isArchivedContracts &&
								<Button
									className="button-archive button-text button-shift"
									onClick={() => this.onCheckedMultiContrat(true)}
									type="submit"
								>
									<i className="fa fa-archive fa-lg icone-button-text"> </i> Archiver
								</Button>
								}
								{ this.state.archivePending && isAdmin &&
								isArchivedContracts &&
								<Button
									className="button-archive button-text button-shift"
									onClick={() => this.onCheckedMultiContrat(false)}
									type="submit"
								>
									<i className="fa fa-archive fa-lg icone-button-text"> </i> Désarchiver
								</Button>
								}
								{ (!this.state.deletePending && !this.state.archivePending) &&
								isAdmin &&
									<Button
										className="button-remove button-text"
										onClick={() =>
											this.setState({deletePending : true})
										}
									>
										<i className="fa fa-trash fa-lg icone-button-text"> </i> Supprimer
									</Button>
								}
								{ (!this.state.archivePending && !this.state.deletePending) &&
								!isArchivedContracts && isSyncedContracts && isAdmin &&
								<Button
									className="button-archive button-text"
									onClick={() =>
										this.setState({archivePending : true})
									}
								>
									<i className="fa fa-archive fa-lg icone-button-text"> </i> Archiver
								</Button>
								}
								{ (!this.state.archivePending && !this.state.deletePending) &&
								isArchivedContracts && isAdmin &&
								<Button
									className="button-archive button-text"
									onClick={() =>
										this.setState({archivePending : true})
									}
								>
									<i className="fa fa-archive fa-lg icone-button-text"> </i> Désarchiver
								</Button>
								}
								{isAdmin && (isSyncedContracts || isArchivedContracts) &&
								(!this.state.deletePending && !this.state.archivePending) &&
									<Button
										className="button-export button-text"
										onClick={() =>
											this.refs.exportPopup.handleOpen()
										}
									>
										<i className="fa fa-file fa-lg icone-button-text"> </i> Exporter
									</Button>
								}
								{(isSeller || isAdmin) && !isArchivedContracts &&
								(!this.state.deletePending && !this.state.archivePending) &&(
									<>
										<Button
											className="button-sync button-text"
											onClick={onSyncAll}
										>
											<i className="fa fa-refresh fa-lg icone-button-text"> </i>	Synchroniser
										</Button>
										<Button
											className="button-create button-text"
											onClick={Gocreate}
										>
											<i className="fa fa-clipboard fa-lg icone-button-text"> </i> Créer
										</Button>
									</>
								)}
							</div>
						</div>
					</CardHeader>



					<div className="topFiltre">
						{!isToSyncedContrats &&
							<div className="date-filters group-filters">

								<button
									className="button-reload-filters"
									onClick={() => this.reloadFilters()}
								>
									<i className="fa fa-times cross-delete fa-3x "> </i>
								</button>

								<div className="filtre filters-contract">
									<label>Entre le </label>
									<DateField
										isClearable
										value={this.state['created[after]']}
										onChange={value => {
											addFilter({value: value ? moment(value).format('YYYY-MM-DD') : undefined},
												"created[after]")
										}}
									/>
								</div>
								<div className="filtre filters-contract">
									<label>Et le </label>
									<DateField
										isClearable
										value={this.state['created[before]']}
										onChange={value => {
											addFilter({value: value ? moment(value).set({'hour': 23, 'minute': 59}).format('YYYY-MM-DD h:mm:ss a') : undefined}, "created[before]")
										}}
									/>
								</div>
							</div>
						}

						{!isToSyncedContrats &&
							<div className="seller-filters group-filters">
								<div className="filtre filters-contract">
									<label>Secteurs</label>
									<Select
										value={this.state['seller.team.sector']}
										options={this.state.dataSector ? this.state.dataSector.map((sector) => {
											return {value: sector.id, label: sector.name}
										}) : []}
										onChange={value => {
											addFilter(value, "seller.team.sector")
										}}
									>
									</Select>
								</div>
								<div className="filtre filters-contract">
									<label>Équipes</label>
									<Select
										value={this.state['seller.team']}
										options={this.state['seller.team.sector'] ? showTeamsSectors(this.state['seller.team.sector']) : showTeams()}
										onChange={value => {
											addFilter(value, "seller.team")
										}}
									>
									</Select>
								</div>
								<div className="filtre filters-contract">
									<label>Vendeurs</label>
									<Select
										value={this.state['seller']}
										options={this.state['seller.team'] ? showSellersTeams(this.state['seller.team']) : showSellers()}
										onChange={value => {
											addFilter(value, "seller")
										}}
									>
									</Select>
								</div>
							</div>
						}

						<div className="material-filters group-filters">
							{!isToSyncedContrats &&
								<>
								<div className="filtre filters-contract">
									<label> Statut</label>
									<Select
										value={this.state.status}
										options={this.props.status ? this.props.status.map((status) => {
											return {value: status.value, label: status.label}
										}) : []}
										onChange={value => {
											addFilter(value, "status")
										}}>
									</Select>
								</div>
								<div className="filtre filters-contract">
									<label>Thermostat offert</label>
									<Select
										value={this.state.thermostatOffer}
										options={this.thermostatOffer()}
										onChange={value => {
											addFilter(value, "thermostatOffer")
										}}>
									</Select>
								</div>
								</>
							}
							<div className="filtre filters-contract">
								<label>Équipements</label>
								<Select
									value={this.state.alreadyInstalled}
									options={this.alreadyInstall()}
									onChange={value => {
										addFilter(value, "alreadyInstalled")
									}}>
								</Select>
							</div>
						</div>


						<div className="customer-filter group-filters">
							<div className="filtre filters-contract">
								<label>Prénom du client</label>
								<Input
									value={this.state["customer.firstName"]}
									onChange={event => {
										addFilter({value: event.target.value}, "customer.firstName")
									}}>
								</Input>
							</div>
							<div className="divExchange" >
								<button disabled={this.state.exchangePending} className="exchange " onClick={()=>this.exchangeName()}><i className="fa fa-exchange" aria-hidden="true"></i></button>
							</div>
							<div className="filtre filters-contract">
								<label>Nom du client</label>
								<Input
									value={this.state["customer.lastName"]}
									onChange={event => {
										addFilter({value: event.target.value}, "customer.lastName")
									}}>
								</Input>
							</div>
							<div className="filtre filters-contract">
								<label>Ville</label>
								<Input
									value={this.state["customer.city"]}
									type="text"
									onChange={event => {
										addFilter({value: event.target.value}, "customer.city")
									}}>
								</Input>
							</div>
						</div>

						{!isToSyncedContrats &&
							<div className="number-filters group-filters">
								{(isSyncedContracts === true && isArchivedContracts === false)
									? <div className="filtre_nbr_contract filters-contract">
										<label>Total Contrats :</label>
										<div className="contract_filter">
											{this.props.totalSyncedContract.totalContract}
										</div>
									</div>
									: <div className="filtre_nbr_contract filters-contract">
										<label>Total Contrats :</label>
										<div className="contract_filter">
											{this.props.totalArchivedContract.totalContract}
										</div>
									</div>
								}
								<div className="filtre_nbr_contract filters-contract">
									<label>Parc :</label>
									<div className="contract_filter"> {this.state.totalParc}</div>
								</div>
								<div className="filtre_nbr_contract filters-contract">
									<label>pas Parc :</label>
									<div className="contract_filter"> {this.state.totalPasParc}</div>
								</div>
							</div>
						}
					</div>


					<WHTable
						data={data}
						pages={pages}
						columns={columns()}
						loading={!this.state.loaded}
						defaultFiltered={this.defaultStatusFilter}
						manual={manual}
						onFetchData={onLoad}
						actualPage={this.state.initialPage}
					/>
				</Card>
			</div>
		);
	}
}

ContractsList
	.defaultProps = {
	onLoad: () => {
	},
	onRemove: () => {
	},

	isSyncedContracts: false,
	isArchivedContracts: false
};

ContractsList.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	status: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	loaded: PropTypes.bool.isRequired,
	manual: PropTypes.bool.isRequired,
	isOnline: PropTypes.bool.isRequired,
	roles: PropTypes.arrayOf(PropTypes.any).isRequired,
	setParentState: PropTypes.func.isRequired,
	onSyncContract: PropTypes.func.isRequired,
	onSyncAll: PropTypes.func.isRequired,
	onSaveContract: PropTypes.func.isRequired,
	onFetchCurrentContract: PropTypes.func.isRequired,
	onRemove: PropTypes.func,
	onLoad: PropTypes.func,
	isSyncedContracts: PropTypes.bool,
	isArchivedContracts: PropTypes.bool,
	pages: PropTypes.number.isRequired,
	contractType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
	isSeller: getIsSeller(state),
	status: getContractsStatus(state),
	isOnline: getIsOnline(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onSyncContract: syncOneContract,
			onSyncAll: syncAllContracts,
			onSaveContract: addContractToSync,
			onFetchCurrentContract: fetchContract,
			onSetStatusFilter: setStatusFilter
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContractsList);
