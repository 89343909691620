import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {Container} from "reactstrap";

import Dashboard from "../../Dashboard";
import DisabledMembers from "../../Members/DisabledUsers";
import EnabledMembers from "../../Members/EnabledUsers";
import MyInformations from "../../Account/MyInformations";
import MyPassword from "../../Account/MyPassword";
import Equipments from "../../Catalog/Equipment";
import Families from "../../Catalog/Family";
import Services from "../../Catalog/Service/Services";
import Prices from "../../Catalog/Price";
import Contract from "../../Contract";
import Signature from "../../Signature";
import Contracts from "../../Contract/List/Completed";
import Customers from "../../Customer";
import Teams from "../../Teams/TeamList";
import CommercialsSectors from "../../Sectors/CommercialsSectors";
import TechniquesSectors from "../../Sectors/TechniquesSectors";
import ContractsToSync from "../../Contract/List/ToSync";
import ArchivedContracts from "../../Contract/List/Archived";
import Sync from "../../Sync";
import Preview from "../../Preview";
import TechnicianAppointmmentList from "../../Appointment/TechnicianAppointmentList";
import SuiviEntretien from "../../Appointment/SuiviEntretien/SuiviEntretien";
import Publipostage from "../../Appointment/Publipostage";

import {getPathID, getComplexPathID} from "@/utils/getPath";
import {
	DASHBOARD,
	SYNC,
	CONTRACTS,
	CUSTOMERS,
	PRICES,
	EQUIPMENTS,
	SERVICES,
	MEMBERS,
	DISABLED_MEMBERS,
	FAMILIES,
	ACCOUNT_PASSWORD,
	ACCOUNT_INFOS,
	ROOT,
	CONTRACTS_TO_SYNC,
	SIGNATURE,
	PREVIEW,
	TYPE_PARAM,
	ARCHIVED_CONTRACTS,
	TEAMS,
	SUIVI_ENTRETIEN,
	ENTRETIEN_PAR_TECHNICIEN,
	PUBLIPOSTAGE,
	COMMERCIALS_SECTORS,
	TECHNIQUES_SECTORS, TECHNIQUE_TEAMS, COMMERCIAL_TEAMS, PORTEFEUILLE, AttestationTva,
} from "../../../constants/paths";
import CommercialTeams from "../../Teams/CommercialTeams";
import TechniqueTeams from "../../Teams/TechniqueTeams";
import Portefeuille from "../../Customer/PorteFeuilleClient/Portefeuille";
import AttestationTVA from "../../AttestationTVA/AttestationTva";

const Routes = () => (
	<Container fluid>
		<Switch>
			<Route path={DASHBOARD} name="Dashboard" component={Dashboard}/>
			<Route
				path={MEMBERS}
				name="Gestion des membres activés"
				component={EnabledMembers}
			/>
			<Route
				path={DISABLED_MEMBERS}
				name="Gestion des membres désactivés"
				component={DisabledMembers}
			/>
			<Route
				path={SUIVI_ENTRETIEN}
				name="Suivi des entretiens"
				component={SuiviEntretien}
			/>
			<Route
				path={PUBLIPOSTAGE}
				name="Suivi des entretiens"
				component={Publipostage}
			/>

			<Route
				path={AttestationTva}
				name="Attestation TVA"
				component={AttestationTVA}
			/>
			<Route
				path={ENTRETIEN_PAR_TECHNICIEN}
				name="Entretiens par technicien"
				component={TechnicianAppointmmentList}
			/>
			<Route
				path={EQUIPMENTS}
				name="Gestion des équipements"
				component={Equipments}
			/>
			<Route
				path={SERVICES}
				name="Gestion des niveau de préstation"
				component={Services}
			/>
			<Route path={PRICES} name="Gestion des tarifs" component={Prices}/>
			<Route
				path={FAMILIES}
				name="Gestion des Familles d'équipements"
				component={Families}
			/>
			<Route
				path={ACCOUNT_INFOS}
				name="Mon compte"
				component={MyInformations}
			/>
			<Route
				path={ACCOUNT_PASSWORD}
				name="Mon compte"
				component={MyPassword}
			/>
			<Route
				path={getPathID(CONTRACTS)}
				name="Contrat"
				component={Contract}
			/>
			<Route path={CONTRACTS} name="Contrats" component={Contracts}/>
			<Route
				path={CONTRACTS_TO_SYNC}
				name="Contrats"
				component={ContractsToSync}
			/>
			<Route
				path={ARCHIVED_CONTRACTS}
				name="Contrats archivés"
				component={ArchivedContracts}
			/>
			<Route path={SYNC} name="Synchronisation" component={Sync}/>
			<Route
				exact
				path={getComplexPathID(PREVIEW, TYPE_PARAM)}
				name="Prev Page"
				component={Preview}
			/>
			<Route
				path={getComplexPathID(SIGNATURE, TYPE_PARAM)}
				name="Signature"
				component={Signature}
			/>
			<Route path={CUSTOMERS} name="Clients" component={Customers}/>
			<Route path={PORTEFEUILLE} name="Portefeuille Client" component={Portefeuille}/>
			<Route path={TECHNIQUE_TEAMS} name="Equipes Technique" component={TechniqueTeams}/>
			<Route path={COMMERCIAL_TEAMS} name="Equipes Commercial" component={CommercialTeams}/>
			<Route path={COMMERCIALS_SECTORS} name="Secteurs Commerciaux" component={CommercialsSectors}/>
			<Route path={TECHNIQUES_SECTORS} name="Sectreurs Techniques" component={TechniquesSectors}/>
			<Redirect from={ROOT} to={DASHBOARD}/>
		</Switch>
	</Container>
);

export default Routes;
