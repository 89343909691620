import {createAction} from "redux-friends";
import {toast} from "react-toastify";

import {getAllContractsToSyncList, getOneContractToSync} from "./selectors";
import {
	create,
	search,
	update,
	fetchStatus,
	remove,
	view
} from "@/services/contract";
import formatDataForRedux from "@/utils/formatDataForRedux";
import ifContractIsToBeSynced from "@/utils/ifContractIsToBeSynced";
import {
	REMOVE_CONTRACT_FROM_SYNC,
	ADD_CONTRACT_TO_SYNC,
	EDIT_CONTRACT_TO_SYNC,
	SET_CONTRACTS,
	SET_CONTRACTS_STATUS,
	SET_SIGNATURE,
	SET_CURRENT_CONTRACT,
	SET_STATUS_FILTER,
	SET_ARCHIVED_CONTRACTS,
	ContractsTypes,
} from "./constants";
import {formatContractForSave} from "../utils";
import {TMP_ID} from "../../../constants";

export const setArchivedContracts = createAction(
	ContractsTypes[SET_ARCHIVED_CONTRACTS]
);
export const setContracts = createAction(ContractsTypes[SET_CONTRACTS]);

export const removeContractFromToSync = createAction(
	ContractsTypes[REMOVE_CONTRACT_FROM_SYNC]
);
export const addContractToSync = createAction(
	ContractsTypes[ADD_CONTRACT_TO_SYNC]
);
export const editContractToSync = createAction(
	ContractsTypes[EDIT_CONTRACT_TO_SYNC]
);
export const setContractsStatus = createAction(
	ContractsTypes[SET_CONTRACTS_STATUS]
);
export const setSignature = createAction(ContractsTypes[SET_SIGNATURE]);
export const setCurrentContract = createAction(
	ContractsTypes[SET_CURRENT_CONTRACT]
);
export const setStatusFilter = createAction(ContractsTypes[SET_STATUS_FILTER]);

const fetchContracts = (state, set = setContracts, initialPage = 0) => async dispatch => {
	try {
		const data = await search(state, initialPage);
		dispatch(set(formatDataForRedux(data,  state.pageSize)));
	} catch (error) {
		console.error({error});
		throw new Error(error);
	}
};

const enhanceSearchWithArchivedStatus = (state, isArchived) => ({
	...state,
	filtered: [
		...(state.filtered ? state.filtered : []),
		{id: "archived", value: isArchived ? 1 : 0}
	]
});

export const fetchOnGoingContracts = (state = {}) =>
	fetchContracts(enhanceSearchWithArchivedStatus(state, false), setContracts);

export const fetchArchivedContracts = (state = {}) =>
	fetchContracts(
		enhanceSearchWithArchivedStatus(state, true),
		setArchivedContracts
	);

export const fetchContractsStatus = () => async dispatch => {
	try {
		const status = await fetchStatus();
		dispatch(setContractsStatus(status));
	} catch (error) {
		console.error({error});
	}
};

export const updateContract = contract => async dispatch => {
	try {
		await update(contract);
		await fetchOnGoingContracts()(dispatch);
	} catch (error) {
		console.error({error});
	}
};

export const fetchContract = contractId => async dispatch => {
	try {
		const contract = await view(contractId);
		dispatch(
			setCurrentContract({
				...contract,
				affectedSeller: contract.affectedSeller
					? contract.affectedSeller["@id"]
					: null
			})
		);
	} catch (error) {
		console.error({error});
	}
};

export const syncAllContracts = state => async (dispatch, getState) => {
	const contracts = getAllContractsToSyncList(getState()).filter(
		ifContractIsToBeSynced
	);
	if (contracts.length !== 0){
		for (const contract of contracts) {
			try {
				await create(formatContractForSave(contract));
				dispatch(removeContractFromToSync(contract[TMP_ID]));
			} catch (error) {
				console.error({error});
			}
		}
		try {
			await fetchOnGoingContracts(state)(dispatch);
			return true
		} catch (error) {
			console.error({error});
		}
	}else{
		return false
	}
};

export const syncOneContract = tmpID => async (dispatch, getState) => {
	const contract = getOneContractToSync(getState(), tmpID);
	try {
		await create(formatContractForSave(contract));
		dispatch(removeContractFromToSync(contract[TMP_ID]));
		toast.success("contrat synchronisé !");
	} catch (error) {
		console.error({error});
		toast.error("erreur lors de la synchronisation du contrat !");
	}
};

export const removeContract = deleteID => async dispatch => {
	try {
		await remove(deleteID);
		fetchOnGoingContracts()(dispatch);
		fetchArchivedContracts()(dispatch);
	} catch (error) {
		console.error({error});
		alert(
			"Il existe des dépendances sur ce contrat, il ne peut donc être supprimé"
		);
	}
};
