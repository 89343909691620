import moment from 'moment';
import isUndefined from 'lodash.isundefined';
import {SEPA} from '../../constants';
import {getUniqueTempID} from '@/utils/uuid';
import {TMP_ID} from './constants';
import Moment from "react-moment";

export const canSetReference = contract =>
	isUndefined(contract.reference) &&
	contract.installationDate &&
	contract.customer &&
	contract.customer.lastName;

export const formatContractForSave = data => {
	if (!isUndefined(data.promotion)) {
		data.promotion = parseFloat(data.promotion);
	}
	data.contractInformation.map(contractInfo => {
		if (!isUndefined(contractInfo.nbrUnite)) {
			contractInfo.nbrUnite = parseFloat(contractInfo.nbrUnite);
		}
		if (!isUndefined(contractInfo.nbrUniteInt)) {
			contractInfo.nbrUniteInt = parseFloat(contractInfo.nbrUniteInt);
		}
	})

	if (!isUndefined(data.firstReglement)) {
		if (typeof data.firstReglement === 'string') {
			data.firstReglement = data.firstReglement === 'true';
		} else {
			data.firstReglement = data.firstReglement === true;
		}
	}

	if (!isUndefined(data.thermostatOffer)) {
		if (typeof data.thermostatOffer === 'string') {
			data.thermostatOffer = data.thermostatOffer === 'true';
		} else {
			data.thermostatOffer = data.thermostatOffer === true;
		}
	}

	if (!isUndefined(data.archived)) {
		if (typeof data.archived === 'string') {
			data.archived = data.archived === 'true';
		} else {
			data.archived = data.archived === true;
		}
	}

	if (!isUndefined(data.status)) {
		data.status = parseFloat(data.status);
	}
	if (!isUndefined(data.monthly)) {
		data.monthly = data.monthly.toString();
	}
	if (canSetReference(data)) {
		data.reference = `${data.customer.lastName}${moment(data.installationDate).format(
			'YYYYMMDD'
		)}`;
	}

	return data;
};

export const formatInstallationDate = item => {
	if (item.installationDate) {
		item.installationDate = moment(item.installationDate).format('YYYY-MM-DD')
	}
	return item
};

export const checkIfContractValid = contract => {
	if (!contract) {
		return false;
	}
	const {customer, levelService, identity, paymentType, bic, iban} = contract;
	const {civility, firstName, lastName, address, zipCode, country, email, mobile} = customer;
	const mustBe = [
		civility,
		firstName,
		address,
		lastName,
		zipCode,
		country,
		email,
		mobile,
		levelService,
		identity,
		paymentType,
	];

	if (paymentType === SEPA) {
		mustBe.push(bic, iban);
	}

	return mustBe.every(x => !!x);
};

export const checkIfSepaValid = currentContract =>
	currentContract &&
	currentContract.paymentType === 'sepa' &&
	!!currentContract.bic &&
	!!currentContract.iban;

export const getDefaultData = () => ({
	created: moment(new Date()).toDate(),
	promotion: 0,
	firstReglement: false,
	thermostatOffer: false,
	customer: {
		civility: 'Mme',
		country: 'France',
	},
	[TMP_ID]: getUniqueTempID(),
	contractInformation: [],
	sepaSignature: '',
	alreadyInstalled: false,
	parc: false,
	contractSignature: '',
	contractSellerSignature: '',
	paymentType: 'sepa',
	monthly: 0,
	levelService: '/api/level_services/1',
});
