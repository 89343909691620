export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";
export const SUIVIE = "/suivi_entretien"
export const ENTECHNICIENS = "/entretien_techniciens"

export const ROOT = "/";
export const CUSTOMERS = "/customers";
export const ACCOUNT = "/account";
export const ACCOUNT_PASSWORD = `${ACCOUNT}/password`;
export const ACCOUNT_INFOS = `${ACCOUNT}/informations`;

export const OPPORTUNITIES = "/opportunities";
export const CONTRACTS = "/contracts";
export const SUIVI_ENTRETIEN = "/suivi_entretien";
export const AttestationTva = "/AttestationTva";
export const PUBLIPOSTAGE = "/publipostage";
export const ENTRETIEN_PAR_TECHNICIEN = "/entretien_techniciens";
export const CONTRACTS_TO_SYNC = "/contracts-to-sync";
export const ARCHIVED_CONTRACTS = "/contracts-archived";
export const PAGES = "/pages";
export const PORTEFEUILLE = "/portefeuille-clients";
export const POSTS = "/posts";
export const SYNC = "/sync";
export const SIGNATURE = "/signature";
export const PRICES = "/prices";
export const DISABLED_MEMBERS = "/disabled-members";
export const MEMBERS = "/members";
export const MEMBERS_DISABLED = "/disabled-members"
export const EQUIPMENTS = "/equipments";
export const SERVICES = "/services";
export const FAMILIES = "/families";
export const PREVIEW = "/preview";
export const TYPE_PARAM = ":type";
export const TEAMS = "/teams";
export const COMMERCIAL_TEAMS = "/commercial-teams";
export const TECHNIQUE_TEAMS = "/technique-teams";
export const SECTORS = "/sectors";
export const COMMERCIALS_SECTORS = "/commercials-sectors";
export const TECHNIQUES_SECTORS = "/technicals-sectors";
export const PAGE404 = "/404";
